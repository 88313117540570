import { ProfileDocumentModel } from '@eros-api/models';
import { createAction, props } from '@ngrx/store';

import {
  ISortingDefaultModel,
  IUpdatePaginationDefaultModel
} from '@app-ngrx-utils/base-model';
import { createFeatureActionType } from '@app/store/utils';

import { IPendingDocumentsFeatureFiltersState } from '../models/pending-documents-future.model';

const pendingDocumentsPageActionsNamespace = createFeatureActionType(
  'Pending Documents Page'
);

export const loadPendingDocuments = createAction(
  `${pendingDocumentsPageActionsNamespace} Load Pending Documents`
);
export const loadPendingDocumentsSuccess = createAction(
  `${pendingDocumentsPageActionsNamespace} Load Pending Documents Success`,
  props<{ documents: ProfileDocumentModel[] }>()
);
export const loadPendingDocumentsFailure = createAction(
  `${pendingDocumentsPageActionsNamespace} Load Pending Documents Failure`,
  props<{ error: Error }>()
);

export const updatePagination = createAction(
  `${pendingDocumentsPageActionsNamespace} Update Pending Documents Pagination`,
  props<IUpdatePaginationDefaultModel>()
);

export const updateSorting = createAction(
  `${pendingDocumentsPageActionsNamespace} Update Pending Documents Sorting`,
  props<{ payload: ISortingDefaultModel }>()
);

export const updateFilter = createAction(
  `${pendingDocumentsPageActionsNamespace} Update Ticket Filter`,
  props<{ payload: Partial<IPendingDocumentsFeatureFiltersState> }>()
);

export const deleteDocument = createAction(
  `${pendingDocumentsPageActionsNamespace} Delete Document`,
  props<{ id: string }>()
);
