import { ICallState, SortDirection } from '@app-ngrx-utils/base-model';
import { DEFAULT_INITIAL_PAGINATION_DATA } from '@app-ngrx-utils/constants';
import { CallState, createFeatureFeatureKey } from '@app/store/utils';

import { ProfileDocumentModel } from '@eros-api/models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import * as pendingDocumentsPageActions from './actions/pending-document.actions';

import {
  IPendingDocumentsEntityState,
  IPendingDocumentsFeatureState
} from './models/pending-documents-future.model';

export const pendingDocumentsFeatureKey =
  createFeatureFeatureKey('pending-documents');

const initialCallState: ICallState = { callState: CallState.Init, error: null };

export const pendingDocumentsEntityAdapter: EntityAdapter<ProfileDocumentModel> =
  createEntityAdapter<ProfileDocumentModel>({
    selectId: (entity) => entity.id,
    sortComparer: false
  });

const initialState: IPendingDocumentsEntityState =
  pendingDocumentsEntityAdapter.getInitialState();

export const entitiesFeature = createFeature({
  name: 'pendingDocumentsEntities',
  reducer: createReducer(
    initialState,
    on(
      pendingDocumentsPageActions.loadPendingDocumentsSuccess,
      (state, { documents }) =>
        pendingDocumentsEntityAdapter.setAll(documents, {
          ...state
        })
    ),
    on(pendingDocumentsPageActions.deleteDocument, (state, { id }) =>
      pendingDocumentsEntityAdapter.removeOne(id, {
        ...state
      })
    )
  )
});

export const paginatorFeature = createFeature({
  name: 'pendingDocumentsPaginator',
  reducer: createReducer(
    DEFAULT_INITIAL_PAGINATION_DATA,
    on(pendingDocumentsPageActions.updatePagination, (state, payload) => ({
      ...state,
      ...payload
    }))
  )
});

export const sortFeature = createFeature({
  name: 'pendingDocumentsSort',
  reducer: createReducer(
    {
      sortDirection: SortDirection.DESC,
      sortBy: 'accountId'
    },
    on(pendingDocumentsPageActions.updateSorting, (state, { payload }) => ({
      ...state,
      ...payload
    }))
  )
});

export const filtersFeature = createFeature({
  name: 'pendingDocumentsFilter',
  reducer: createReducer(
    {},
    on(pendingDocumentsPageActions.updateFilter, (state, { payload }) => ({
      ...state,
      ...payload
    }))
  )
});

export const callStateFeature = createFeature({
  name: 'callState',
  reducer: createReducer(
    initialCallState,
    on(pendingDocumentsPageActions.loadPendingDocuments, (state) => ({
      ...state,
      callState: CallState.Loading
    })),
    on(pendingDocumentsPageActions.loadPendingDocumentsSuccess, (state) => ({
      ...state,
      callState: CallState.Loaded
    })),
    on(pendingDocumentsPageActions.loadPendingDocumentsFailure, (state) => ({
      ...state,
      callState: CallState.Error
    }))
  )
});

export const pendingDocumentsFeature = createFeature({
  name: pendingDocumentsFeatureKey,
  reducer: combineReducers<IPendingDocumentsFeatureState>({
    entities: entitiesFeature.reducer,
    paginator: paginatorFeature.reducer,
    sort: sortFeature.reducer,
    filters: filtersFeature.reducer,
    callState: callStateFeature.reducer
  })
});
