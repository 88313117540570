import { formatReferenceName } from '@app/libs/account/utils/format-reference-name';

import { IPendingDocumentsFeatureFiltersState } from '../models/pending-documents-future.model';
import { IPendingDocumentViewModel } from '../../models/pending-document-view.model';

export function filterPendingDocuments(
  documents: IPendingDocumentViewModel[],
  filters: IPendingDocumentsFeatureFiltersState
) {
  return documents.filter((document) => {
    let match = true;

    if (match && filters.type) {
      match = document.type === filters.type;
    }

    if (match && filters.accountId) {
      const searchTerm = filters.accountId.toString().toLowerCase();
      match =
        document.accountId.toString().toLowerCase().includes(searchTerm) ||
        document.person.name.toLowerCase().includes(searchTerm) ||
        document.type.toLowerCase().includes(searchTerm) ||
        (document.createdByAccount &&
          formatReferenceName(document.createdByAccount)
            .toLowerCase()
            .includes(searchTerm));
    }

    return match;
  });
}
