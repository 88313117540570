import { createSelector } from '@ngrx/store';
import { filterPendingDocuments } from '../utils';
import { compare } from '@app/store/utils/compare';
import { paginateData } from '@app/store/utils/paginate';

import {
  pendingDocumentsEntityAdapter,
  pendingDocumentsFeature,
  pendingDocumentsFeatureKey
} from '../pending-documents.feature';
import { IDocumentsState } from '../models/pending-documents-future.model';

export const { selectAll } = pendingDocumentsEntityAdapter.getSelectors();

const selectPendingDocumentsFeatureState = (state: IDocumentsState) =>
  state[pendingDocumentsFeatureKey];

const selectAllEntities = createSelector(
  selectPendingDocumentsFeatureState,
  (state) => selectAll(state.entities)
);

export const selectPendingDocumentsPaginatorState = createSelector(
  selectPendingDocumentsFeatureState,
  (state) => state.paginator
);

const selectPageIndex = createSelector(
  selectPendingDocumentsPaginatorState,
  (paginator) => paginator.pageIndex
);
const selectPageSize = createSelector(
  selectPendingDocumentsPaginatorState,
  (paginator) => paginator.pageSize
);

export const selectPendingDocumentsSortState = createSelector(
  selectPendingDocumentsFeatureState,
  (state) => state.sort
);

export const selectPendingDocumentsFiltersState = createSelector(
  selectPendingDocumentsFeatureState,
  (state) => state.filters
);

export const selectCallState = createSelector(
  pendingDocumentsFeature.selectCallState,
  (state) => state.callState
);

export const selectPendingDocuments = createSelector(
  selectAllEntities,
  selectPendingDocumentsFiltersState,
  (documents, filters) => filterPendingDocuments(documents, filters)
);

export const selectSortedPendingDocuments = createSelector(
  selectPendingDocuments,
  selectPendingDocumentsSortState,
  (pendingDocuments, sort) => {
    if (sort.sortBy) {
      return pendingDocuments.sort((a, b) => {
        const sortA = a[sort.sortBy];
        const sortB = b[sort.sortBy];
        return compare(sortA, sortB, sort.sortDirection);
      });
    }

    return pendingDocuments;
  }
);

export const selectPaginatedPendingDocuments = createSelector(
  selectPendingDocumentsSortState,
  selectSortedPendingDocuments,
  selectPageIndex,
  selectPageSize,
  (_, pendingDocuments, pageIndex, pageSize) =>
    paginateData(pendingDocuments, pageIndex, pageSize)
);
