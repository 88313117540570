import {
  selectPendingDocuments,
  selectCallState,
  selectSortedPendingDocuments,
  selectPaginatedPendingDocuments,
  selectPendingDocumentsSortState,
  selectPendingDocumentsPaginatorState,
  selectPendingDocumentsFiltersState
} from './selectors/pending-documents.selectors';

export const pendingDocumentsSelectors = {
  selectPendingDocuments,
  selectCallState,
  selectSortedPendingDocuments,
  selectPaginatedPendingDocuments,
  selectPendingDocumentsSortState,
  selectPendingDocumentsPaginatorState,
  selectPendingDocumentsFiltersState
};

export * as pendingDocumentsPageActions from './actions/pending-document.actions';

export {
  pendingDocumentsFeatureKey,
  pendingDocumentsFeature
} from './pending-documents.feature';
